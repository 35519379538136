// import libraries
import React from "react"
import Helmet from "react-helmet"
import styled, { ThemeProvider, css } from "styled-components"

// import styles and custom react hooks
import theme from "../styles/theme"
import { media } from "../styles/media"
import GlobalStyle from "../styles/globalStyle"
import { HeaderOverWrappedProvider } from "../hooks/HeaderOverWrappedContext"
// import file
import favicon from "../images/favicon.ico"

// import components
import Header from "./Header"
import Footer from "./Footer"
import HeaderTop from "./HeaderTop"

const StyledLayoutWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  color: #292929;
  main {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-left: 60px;
    padding-top: 60px;
  }
  ${() =>
    media.sp(css`
      main {
        padding-left: 0;
        font-size: 14px;
      }
    `)}
`

const Layout = ({ children }) => {
  return (
    <StyledLayoutWrapper>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <HeaderOverWrappedProvider>
          <Header />
          <HeaderTop />
          <main>{children}</main>
          <Footer />
        </HeaderOverWrappedProvider>
      </ThemeProvider>
    </StyledLayoutWrapper>
  )
}

export default Layout
