import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import CorpLogo from "../images/francoisLogoNonBorder.svg"
import UnderlineLinks from "./UnderlineLinks"
import { media } from "../styles/media"

const StyledDiv = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 999;
  background: white;
  box-shadow: 0px 1px 0px #dadada;
  header {
    width: 100%;
    height: 100%;
    padding-right: 5%;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .headRight {
      width: 60px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      ${() =>
        media.sp(css`
          margin-left: 3%;
        `)}
      a img {
        width: 50px;
      }
    }
    .headLeft {
      ${() =>
        media.sp(css`
          display: none;
        `)}
      margin-right:0px;
      width: 300px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }
`

const HeaderTop = () => (
  <StyledDiv>
    <header>
      <div className="headRight">
        <Link to="/">
          <img src={CorpLogo} alt="フランソア会社ロゴ" />
        </Link>
      </div>
      <div className="headLeft">
        <UnderlineLinks color="#E50012" link2="/inquiry/" text="お問合せ" />
        <UnderlineLinks color="#008742" link2="/recruitment/" text="採用情報" />
      </div>
    </header>
  </StyledDiv>
)

export default HeaderTop
