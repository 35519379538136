import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import { IconContext } from "react-icons"
import { media } from "../styles/media"

const StyledDiv = styled.div`
  width: 110px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  a {
    font-size: 0.9em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 110px;
    svg {
      opacity: 0;
      transform: translateX(-5px);
      transition: 0.3s;
    }
    :hover {
      svg {
        opacity: 0.9;
        transform: translateX(0);
      }
    }
  }
  .underLink {
    margin-top: 2px;
    width: 100%;
    height: 2px;
    border-radius: 10px;
  }
  ${() =>
    media.tablet(css`
      a {
        padding: 0 0.62em;
        svg {
          opacity: 1;
        }
      }
    `)}
`

const UnderlineLinks = props => {
  return (
    <StyledDiv>
      <Link to={props.link2}>
        {props.text}
        <IconContext.Provider value={{ color: props.color, size: "1.3em" }}>
          <BsArrowRight />
        </IconContext.Provider>
      </Link>
      <div className="underLink" style={{ backgroundColor: props.color }}></div>
    </StyledDiv>
  )
}

export default UnderlineLinks
